import React, {Fragment} from 'react'
import {Provider} from 'react-redux'
import Loadable from 'react-loadable'
import {Router, Switch, Route} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import Nothing from 'lyjs/es/components/Nothing'
import {LocaleProvider} from 'antd'
import locale from 'antd/lib/locale-provider/zh_CN'

import {name, version, description, SHA} from 'services/constants'
import store from 'services/store'
import history from 'services/history'
import './styles.module.css'

const Main = Loadable({
  loading: Nothing,
  loader: () => import('scenes/Main')
})
const Signin = Loadable({
  loading: Nothing,
  loader: () => import('scenes/Signin')
})

function App() {
  return (
    <LocaleProvider locale={locale} >
      <Provider store={store} >
        <Router history={history} >
          <Fragment>
            <Helmet>
              <meta
                name="app-meta"
                content={`name=${name},version=${version},description=${description},sha=${SHA}`}
              />
            </Helmet>
            <Switch>
              <Route
                path="/signin"
                component={Signin}
              />
              <Route
                path="/"
                component={Main}
              />
            </Switch>
          </Fragment>
        </Router>
      </Provider>
    </LocaleProvider>
  )
}

export default App
