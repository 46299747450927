import {createReducer, statusStateEnhancer} from 're-reducer'
import updateHelper from 'immutability-helper'

import {prefix, initialState} from './selectors'

const reducer = createReducer({
  prefix,
  initialState
})

const {
  register
} = reducer

export const update = register(
  'UPDATE',
  statusStateEnhancer((state, action) => {
    const {
      payload: {
        mobile,
        token
      }
    } = action

    const nextState = updateHelper(state, {
      $merge: {
        mobile,
        token,
        authorized: !!token,
        pending: false
      }
    })

    return nextState
  })
)

export default reducer
