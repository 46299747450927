import React from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import 'babel-polyfill'
import 'moment/locale/zh-cn'

import 'styles.css'
import App from 'scenes/App'
import {unregister} from 'serviceWorker'
import documentsApi from 'services/api/review/documents'
import faqsApi from 'services/api/review/faqs'
import sensitiveWordsApi from 'services/api/review/sensitiveWords'

moment.locale('zh-cn')
ReactDOM.render(<App />, document.getElementById('root'))
unregister()

window.__api = {
  documents: documentsApi,
  faqs: faqsApi,
  sensitiveWords: sensitiveWordsApi
}
