import {name, version, description} from '../../package.json'

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME
export const SHA = process.env.REACT_APP_SHA || Date.now()
export const TOKEN_CACHE_KEY = process.env.REACT_APP_TOKEN_CACHE_KEY
export const AUTH_HEADER_NAME = process.env.REACT_APP_AUTH_HEADER_NAME
export const REVIEW_API_ENDPOINT =  process.env.REACT_APP_REVIEW_API_ENDPOINT
export {
  name,
  version,
  description
}
