import {createReducer} from 're-reducer'
import updateHelper from 'immutability-helper'
import compose from 'lyjs/es/fp/compose'
import toObject from 'lyjs/es/fp/toObject'
import map from 'lyjs/es/fp/map'
import keep from 'lyjs/es/fp/keep'

import pluralize from 'services/utils/pluralize'
import {prefix, initialState} from './selectors'

function entityEnhancer(next) {
  return (state, action) => {
    const {
      payload,
      meta,
      meta: {
        type,
        idPropName = 'id'
      } = {}
    } = action

    const nextPayload = pluralize(payload)

    if (!type || !nextPayload.length) {
      return state
    }

    const nextAction = {
      ...action,
      payload: nextPayload,
      meta: {
        ...meta,
        type,
        idPropName
      }
    }

    return next(state, nextAction)
  }
}

const reducer = createReducer({
  prefix,
  initialState
})

const {
  register
} = reducer

export const patch = register(
  'PATCH',
  entityEnhancer((state, action) => {
    const {
      payload,
      meta: {
        type,
        idPropName
      }
    } = action

    const entityPatch = toObject(compose(
      map((x) => {
        const {
          [idPropName]: id
        } = x

        if (id === undefined) {
          return null
        }

        return [id, x]
      }),
      keep()
    ), payload)
    const nextState = updateHelper(state, {
      [type]: {
        $apply(entities) {
          if (!entities) {
            return entityPatch
          }

          const nextEntities = updateHelper(entities, {
            $merge: entityPatch
          })

          return nextEntities
        }
      }
    })

    return nextState
  })
)

export const update = register(
  'UPDATE',
  entityEnhancer((state, action) => {
    const {
      payload,
      meta: {
        type,
        idPropName
      }
    } = action

    const patch = toObject(compose(
      map((x) => {
        const {
          [idPropName]: id
        } = x

        if (id === undefined) {
          return null
        }

        return [id, {
          $apply(entity) {
            if (!entity) {
              return x
            }

            const nextEntity = updateHelper(entity, {
              $merge: x
            })

            return nextEntity
          }
        }]
      }),
      keep()
    ), payload)
    const nextState = updateHelper(state, {
      [type]: {
        $apply(entities = {}) {
          const nextEntities = updateHelper(entities, patch)

          return nextEntities
        }
      }
    })

    return nextState
  })
)

export const destroy = register(
  'DESTROY',
  entityEnhancer((state, action) => {
    const {
      payload,
      meta: {
        type
      }
    } = action

    const nextState = updateHelper(state, {
      [type]: {
        $unset: payload
      }
    })

    return nextState
  })
)

export default reducer
