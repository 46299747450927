import {combineReducers} from 'redux'
import {reducer as form} from 'redux-form'

import entities from './entities'
import profile from './profile'

const reducer = combineReducers({
  form,
  entities,
  profile
})

export default reducer
