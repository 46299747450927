import {Modal, notification} from 'antd'

import store from 'services/store'
import {unauthorization} from 'services/store/profile/effects'

export function createError(err, extra, type) {
  return {
    type: type ? `@@ERROR/${type}` : '@@ERROR',
    payload: err,
    error: true,
    ...extra
  }
}

export function reportError(...args) {
  store.dispatch(createError(...args))
}

function errorHandle(store) {
  return (next) => (action) => {
    const {
      type: actionType,
      payload = {},
      meta: {
        ignore,
        type = 'error',
        message,
        description,
        duration
      } = {},
      error
    } = action

    if (error && !/^@@redux-form/.test(actionType)) {
      const timestamp = Date.now()
      const state = store.getState()
      console.info('timestamp:', timestamp)
      console.info('   action:', action)
      console.info('    state:', state)
      console.error(payload)

      if (!ignore) {
        let config

        if (message || description) {
          config = {
            type,
            message,
            description,
            duration
          }
        }

        if (payload.isApiError) {
          const {
            status,
            data
          } = payload.response || {}

          if (status === 401) {
            Modal.error({
              title: '会话已过期',
              content: '会话已过期，请重新登录',
              onOk() {
                store.dispatch(unauthorization())
              }
            })
          } else if (!config) {
            config = {
              type: 'error',
              duration: 3
            }

            switch (status) {
              case 400: {
                config.message = '参数错误'
                config.description = '参数错误，请检查参数是否正确'
                break
              }
              case 404: {
                config.message = '资源不存在'
                config.description = '资源不存在，该资源可能已经被移除'
                break
              }
              case 500: {
                config.message = '服务器错误'
                config.description = '服务器遇到了未知错误，请稍后再试'
                break
              }
              default: {
                config.message = '未知错误'
                config.description = (data && JSON.stringify(data)) || payload.message
              }
            }
          }
        }

        config && notification.open(config)
      }
    }

    next(action)
  }
}

export default errorHandle
