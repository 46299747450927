import tokenCache from 'services/cache/token'

export const prefix = 'profile'
export const initialState = {
  mobile: null,
  token: tokenCache.read(),
  authorized: false,
  pending: true
}

export function mobileSelector(state) {
  return state[prefix].mobile
}
export function tokenSelector(state) {
  return state[prefix].token
}
export function authorizedSelector(state) {
  return state[prefix].authorized
}
export function pendingSelector(state) {
  return state[prefix].pending
}
