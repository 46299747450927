import {decode} from 'jsonwebtoken'
import {SubmissionError} from 'redux-form'

import {authorization as authorizationRequest} from 'services/request'
import authenticateApi from 'services/api/review/authenticate'
import tokenCache from 'services/cache/token'
import {createError} from 'services/store/middlewares/errorHandle'
import {update} from '.'
import {tokenSelector, authorizedSelector} from './selectors'

export function unauthorization() {
  return (dispatch) => {
    dispatch(update({
      username: null,
      token: null
    }))

    tokenCache.destroy()
  }
}

export function authorization(token, callback) {
  return (dispatch, getState) => {
    if (!token) {
      dispatch(unauthorization())

      return
    }

    const state = getState()
    const prevToken = tokenSelector(state)
    const authorized = authorizedSelector(state)

    if (token === prevToken && authorized) {
      return
    }

    return new Promise((resolve) => {
      const {
        mobile,
        exp
      } = decode(token)

      resolve({
        mobile,
        exp
      })
    })
    .then((data) => {
      const {
        mobile,
        exp
      } = data

      if (exp * 1e3 < Date.now()) {
        dispatch(unauthorization())

        return
      }

      const profile = {
        mobile,
        token
      }

      tokenCache.write(token)
      authorizationRequest(token)
      dispatch(update(profile))

      if (callback) {
        callback(undefined, profile)
      }
    })
    .catch((err) => {
      if (callback) {
        callback(err)
      }

      dispatch(update.error(err))
    })
  }
}

export function login(mobile, code, callback) {
  return (dispatch) => {
    authenticateApi
      .login(mobile, code)
      .then((data) => {
        const {
          token
        } = data

        if (callback) {
          callback()
        }

        dispatch(authorization(token))
      })
      .catch((err) => {
        if (callback) {
          if (err.isApiError) {
            const {
              response: {
                status,
                data: {
                  error
                }
              } = {}
            } = err

            if (status === 400) {
              callback(new SubmissionError(error))
            }
          } else {
            callback()

            dispatch(createError(err))
          }
        }
      })
  }
}

export function getCode(mobile, callback) {
  return (dispatch) => {
    authenticateApi
      .getCode(mobile)
      .then(() => {
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        if (callback) {
          callback(err)
        }

        dispatch(createError(err))
      })
  }
}

export function verify(mobile, code, callback) {
  return (dispatch) => {
    authenticateApi
      .verify(mobile, code)
      .then(() => {
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        if (callback) {
          if (err.isApiError) {
            const {
              response: {
                status,
                data: {
                  error
                }
              } = {}
            } = err

            if (status === 400) {
              callback(error)
            }
          } else {
            callback()
            dispatch(createError(err))
          }
        }
      })
  }
}
