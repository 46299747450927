import {createSelector} from 'reselect'

export const prefix = 'entities'
export const initialState = {}

export function createEntitiesSelector(type) {
  return (state) => {
    return state[prefix][type]
  }
}
export function createEntitySelector(entitiesSelector, idPropName = 'id') {
  return (idSelector) => {
    return createSelector(
      entitiesSelector,
      idSelector,
      (entities, id) => {
        const {
          [id]: {
            [idPropName]: entityID,
            pending,
            error,
            ...entity
          } = {}
        } = entities || {}

        return {
          ...entity,
          [idPropName]: entityID,
          pending,
          error,
          isExists: Object.keys(entity).length > 0
        }
      }
    )
  }
}
