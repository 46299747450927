import Review from 'services/request/Review'

class Authenticate extends Review {
  login(mobile, code) {
    return this
      .custom('login')
      .post({
        mobile,
        code
      })
  }

  verify(mobile, code) {
    return this
      .custom('verify')
      .post({
        mobile,
        code
      })
  }

  getCode(mobile) {
    return this
      .custom(`send/${mobile}/code`)
      .get()
  }
}

export default new Authenticate()
