import Review from 'services/request/Review'

class Documents extends Review {
  constructor() {
    super({
      entry: 'documents'
    })
  }

  listReview(reviewed, page, per, keyword, source) {
    return this
      .get({
        params: {
          reviewed,
          page,
          per,
          keyword: keyword ? keyword : undefined,
          source: source === undefined ? undefined : source
        }
      })
  }

  filter(id, status) {
    return this
      .one(id, 'filter')
      .put({
        is_filter: status
      })
  }
}

export default new Documents()
