import Review from 'services/request/Review'

class SensitiveWords extends Review {
  constructor() {
    super({
      entry: 'sensitive/words'
    })
  }
}

export default new SensitiveWords()
