import compose from 'lyjs/es/fp/compose'
import createRestfulApi from 'lyjs/es/request/createRestfulApi'
import {headerAuth} from 'lyjs/es/request/HeaderAuth'

import {
  AUTH_HEADER_NAME,
  REVIEW_API_ENDPOINT
} from 'services/constants'

const Review = compose(
  headerAuth(AUTH_HEADER_NAME),
  createRestfulApi
)({
  baseURL: REVIEW_API_ENDPOINT
})

export default Review
